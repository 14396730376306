<template>
  <div>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Add Feedback Offering Questions </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-row class="pl-4">
        <v-col md="6">
          <label><b>Feedback Type : </b>{{ feedbacktypename }}</label>
        </v-col>

        <v-col md="6">
          <label><b>Academic Year : </b>{{ academicyear }}</label>
        </v-col>
        <v-col md="6">
          <label><b>Semester : </b>{{ semester }}</label>
        </v-col>
        <v-col md="6">
          <label><b>Feedback Number : </b>{{ feedbacknumber }}</label>
        </v-col>
        <v-col md="6">
          <label><b>Start Date : </b>{{ startdate }}</label>
        </v-col>
        <v-col md="6">
          <label><b>End Date : </b>{{ enddate }}</label>
        </v-col>
      </v-row>
    </v-card>
    <br />
    <div>
      <!-- <v-data-table  id="exceltable"  :headers="headers" :items="learnerlist"  class="elevation-1" :search="search" >
                <template v-slot:top class="red">
                    <v-toolbar flat color="white">
                        <v-toolbar-title>Stduent List</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                        <span style="width: 20px"></span>
                    </v-toolbar>
                </template>
            </v-data-table> -->

      <v-simple-table
        id="exceltable"
        class="elevation-1"
        :search="search"
        v-if="feedbackQuestionslist.length != 0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left"><b>Sr. No.</b></th>
              <th class="text-left">
                <b
                  ><v-checkbox
                    :v-model="selectall"
                    v-bind:label="'Select All'"
                    @change="selectAll()"
                  ></v-checkbox
                ></b>
              </th>

              <th class="text-left"><b>Question Type</b></th>
              <th class="text-left"><b>Question Statement </b></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in feedbackQuestionslist" :key="item.srno">
              <td>{{ item.srno }}</td>
              <td>
                <input
                  style="width: 18px; height: 18px"
                  type="checkbox"
                  :id="item.feedbackquestionsId"
                  v-model="item.checked"
                  :checked="item.checked"
                />
              </td>

              <td>{{ item.questiontype }}</td>
              <td>
                {{ item.questionstatement }} 
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">
                <center>
                  <v-btn
                    class="mt-6"
                    color="primary darken-1"
                    @click="saveofferquestion()"
                    >Save</v-btn
                  >
                </center>
                <br />
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
  data: () => ({
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    headArray: [],
    removeArray: [],
    feedbackOfferingQuestionslist: [],
    feedbacktypename: null,
    feedbackQuestionslist: [],
    feedbackOfferingId: "",
    startdate: "",
    enddate: "",
    academicyear: "",
    semester: "",
    feedbacknumber: "",
    learnerlist: [],
    selectall: false,
    headers: [],
  }),

  mounted() {
    this.feedbacktypename = this.$route.params.feedbacktypename;
    this.feedbackOfferingQuestionslist =
      this.$route.params.feedbackOfferingQuestionslist;
    this.feedbackOfferingId = this.$route.params.feedbackOfferingId;

    const data = {
      feedbacktypename: this.feedbacktypename,
      feedbackOfferingId: this.feedbackOfferingId,
    };
    axios
      .post("/TPOFeedbackQuestions/getfbOfferingQuestionslist", data)
      .then((res) => {
        if (res.data.msg == "200") {
          //console.log("getfbOfferingQuestionslist----!");
          this.feedbackOfferingQuestionslist =
            res.data.feedbackOfferingQuestionslist;
          this.feedbackQuestionslist = res.data.feedbackQuestionslist;
          this.feedbackOfferingId = res.data.feedbackOfferingId;
          this.feedbacktypename = res.data.feedbacktypename;
          this.startdate = res.data.startdate;
          this.enddate = res.data.enddate;
          this.academicyear = res.data.academicyear;
          this.semester = res.data.semester;
          this.feedbacknumber = res.data.feedbacknumber;
        } else {
          // console.log("error fetching data!");
        }
      });
  },

  methods: {
    saveofferquestion() {
      for (var i = 0; i < this.feedbackQuestionslist.length; i++) {
        //console.log("checked  ---  " +this.feedbackQuestionslist[i].checked)
        if (this.feedbackQuestionslist[i].checked) {
          this.headArray.push(
            this.feedbackQuestionslist[i].feedbackquestionsId
          );
        } else {
          this.removeArray.push(
            this.feedbackQuestionslist[i].feedbackquestionsId
          );
        }
      }

      this.overlay = true;
      let formData = new FormData();
      formData.append("feedbacktypename", this.feedbacktypename);
      formData.append("feedbackOfferingId", this.feedbackOfferingId);
      for (var i = 0; i < this.headArray.length; i++) {
        formData.append("questionid", this.headArray[i]);
      }
      for (var i = 0; i < this.removeArray.length; i++) {
        formData.append("removwquestionid", this.removeArray[i]);
      }

      axios
        .post("TPOFeedbackQuestions/saveFeedbackOfferedQuestions", formData)
        .then((res) => {
          this.overlay = false;
          if (res.data.status.code == "SUCCESS") {
            this.showSnackbar("#4caf50", "Questions Saved  Successfully..."); // show snackbar on success

            this.$router.push({ name: "offer-feedback-questions" });
          } else if (res.data.status.code == "NA") {
            this.showSnackbar(
              "#b71c1c",
              "Feedback Questions   Already Present!!!"
            ); // show snackbar on error
          }
        })
        .catch((error) => {
          this.overlay = false;
          window.console.log(error);
        })
        .finally(() => {
          this.overlay = false;
        });
    },

    selectAll() {
      this.selectall = !this.selectall;
      //console.log(this.selectall)
      for (var i = 0; i < this.feedbackQuestionslist.length; i++) {
        if (this.selectall) {
          this.feedbackQuestionslist[i].checked = true;
          document.getElementById(
            this.feedbackQuestionslist[i].feedbackquestionsId
          ).checked = true;
        } else {
          this.feedbackQuestionslist[i].checked = false;
          document.getElementById(
            this.feedbackQuestionslist[i].feedbackquestionsId
          ).checked = false;
        }
      }
    },

    exportexcel() {
      $("#exceltable").table2excel({
        name: "Worksheet Name",
        filename: "Student_reg_list", //do not include extension
        fileext: ".xls", // file extension
      });
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
</style>    